#home {

  .home_info {
    background: linear-gradient($accent-color, white);

    h2 {
      text-align: center;
      padding: 4rem;
    }

    h3 {
      text-align: center;
      width: 90%;
      max-width: 1000px;

      @include desktop {
        width: 50%;
      }

    }

    .home_text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5rem;

      p {
        text-align: justify;
        margin-bottom: 2rem;
        width: 90%;
        max-width: 1000px;

        @include desktop {
          width: 50%;
        }
      }

      .button {
        margin-top: 2.5rem;
      }
    }
  }
}