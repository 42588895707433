#privacy_policy {

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    p {
      margin-bottom: 2rem;
      text-align: justify;
      width: 90%;
      max-width: 1000px;

      @include desktop {
        width: 70%;
      }
    }

    h1 {
      margin-bottom: 1.5rem;
      text-align: center;
    }
  }
}