#attractions {

  h1 {
    text-align: center;
  }

  .att_carousel {
    margin-top: 3rem;
  }

  .att_text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    text-align: justify;
    padding-top: 2rem;
    margin-bottom: 2rem;
    width: 90%;
    max-width: 1000px;

    @include desktop {
      width: 70%;
    }
  }
}