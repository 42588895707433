.footer {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(white, $footer-color);

  .phone_mail {
    display: flex;
    justify-content: center;

    a {
      width: 12rem;
      margin: 1rem;

      p {
        text-align: center;
        font-weight: 700;
        font-size: 1.3rem;

        @include mobile {
          font-size: 1rem;
        }
      }

      img {
        width: 20px;
        height: 20px;
      }

      &:last-child {

        img {
          @include desktop {
            margin-left: 2rem;
          }
        }

        p {
          @include mobile {
            margin-left: -.7rem;
          }
        }
      }
    }

    @include mobile {
      align-items: center;
      flex-direction: column;

      a {
        width: 10rem;
        margin: 0;
      }
    }
  }

  .adres {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .privacy {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .links {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    img {
      margin: 0 2rem;
      width: 40px;
      height: 40px;
      filter: grayscale(100%);
      transition: 0.7s;

      &:hover {
        filter: none;
      }
    }

  }

  .good_practices,
  .seniors {
    width: 150px;
    height: 150px;
    margin: 0 2rem;
    margin-bottom: 1rem;

    @include mobile {
      width: 100px;
      height: 100px;
    }
  }

  .copyright {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    a {
      width: 15rem;
    }
  }
}

.maps {
  @include desktop {
    display: flex;
    align-self: center;
  }

  .map {
    width: 100%;
    height: 60vh;

    @include desktop {
      padding: 0 30rem;
      height: 50vh;
    }
  }
}