#header {
  height: 12vh;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1352px;
    margin: 0 auto;

    .main_logo {
      position: absolute;
      width: 100px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    font-family: $font-main;
    font-size: 1rem;
    font-weight: 300;
    color: orange;
    text-decoration: none;
    transition: 2s;

    @media (min-width: 500px) {
      font-size: 2rem;
    }

    @include desktop {

      &:hover {
        color: red;
      }
    }

    h3 {
      margin-left: 7rem;

      @include desktop {
        font-size: 2rem;
      }
    }
  }

  .nav_flags {
    position: fixed;
    top: 10px;
    right: 20px;

    @include mobile {
      top: 70px;
      right: 0;  
    }

    div {
      display: flex;
      flex-direction: column;

      .flag {
        border: 1px solid black;
        width: 40px;
        height: auto;
        margin-top: 10px;
        cursor: pointer;

        @include mobile {
          width: 35px;
          margin-top: 15px;
        }    
      }
    }
  }
}

.terms_cookies {
  color: $accent-color;
}