* {
  box-sizing: border-box;
}

.pages {
  background: linear-gradient(white, $accent-color, white);
}

h1, h2, h3, h4, h5, p {
  font-family: $font-main;
}

h1 {
  margin-top: 2rem;

  @include mobile {
    font-size: 1.8rem;
  }
}

h2 {
  font-size: 2.2rem;

  @include mobile {
    font-size: 1.6rem;
  }
}

p {
  font-size: 1.2rem;

  @include mobile {
    font-size: 1rem;
  }
}