#yurts {

  h1 {
    text-align: center;
  }

  .yurts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2rem 5rem;

    @media (max-width: 479px) {
      padding: 0;
      padding-bottom: 2rem;

    }

    .yurt {
      position: relative;
      border-radius: 50%;
      margin: 2rem;
      height: 25rem;
      width: 25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        margin: 1rem;
        height: 15rem;
        width: 15rem;
      }

      .photo {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        transition: 2s;
        z-index: 2;
        position: absolute;

        &:hover {
          opacity: 0.4;
          z-index: 1;
        }
      }

      &:first-of-type .photo {
        background: url("../../assets/slonce.jpeg") center/cover;
      }

      &:nth-of-type(2) .photo {
        background: url("../../assets/wiatr.jpeg") top/cover;
      }

      &:nth-of-type(3) .photo {
        background: url("../../assets/ogien.jpeg") top/cover;
      }

      &:nth-of-type(4) .photo {
        background: url("../../assets/etno.jpeg") top/cover;
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
        pointer-events: none;
        color: black;
      }

      h3 {
        font-size: 2rem;
        margin-bottom: 1rem;

        @include desktop {
          font-size: 2.5rem;
        }
      }
    }
  }

  .yurts_info {
    text-align: center;
    

    .yurta {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
    }

    .info_logo {
      display: flex;
      text-align: center;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 2rem;

      li {
        list-style-type: none;
        margin: 0 1.5rem;

        .icon {

          @include desktop {
            height: 30px;
          }
        }

        .dog {
          height: 23px;

          @include desktop {
            height: 35px;
          }
        }
      }
    }

    .info_photo {
      display: flex;
      text-align: justify;
      align-items: center;
      flex-direction: column;

      @include desktop {
        width: 80%;
        max-width: 1000px;
      }

      p {
        margin-top: 2rem;

        @include desktop {
          margin: 0;
        }
      }

      .carousel {

        @include desktop {
          width: 600px;
        }

        @media (min-width: 1024px) and (max-width: 1200px) {
          width: 400px;
        }

        .w-100 {
          border-radius: 10%;
        }
      }
    }

    .c1,
    .c3 {

      @include desktop {
        flex-direction: row;
      }

      flex-direction: column-reverse;

      p {

        @include desktop {
          margin-right: 3rem;
        }
      }
    }

    .c2,
    .c4 {

      @include desktop {
        flex-direction: row;
      }

      p {

        @include desktop {
          margin-left: 3rem;
        }
      }
    }

    h2 {
      margin-bottom: 2rem;
      font-size: 2.2rem;
      text-align: center;

      @include desktop {
        font-size: 2.8rem;
        margin-top: 2rem;
        margin-bottom: 3rem;
      }
    }
  }

  .button {
    margin-bottom: 3rem;

    @include desktop {
      margin-top: 2rem;
    }
  }
}