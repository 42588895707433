.header__nav {
  display: flex;
  justify-content: space-between;
}

.nav_menu {
  display: none;

  @include desktop {
    display: flex;

    Link {
      font-family: $font-main;
      font-size: 0.6875rem;
      font-weight: 700;
      letter-spacing: 0.1875rem;
      color: grey;
      text-decoration: none;
      padding: 28px 10px;
      margin: 0 10rem;
      border-bottom: 4px solid transparent;

      &:hover {
        color: black;
        border-color: orange;
      }
    }
  }
}