@mixin mobile {
  @media (max-width: 768px) {
    @content
  }
}

@mixin tablet {
  @media (min-width: 769px) and (max-width: 1023px) {
    @content
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content
  }
}