.nav_btn {
  background: $button-color;
  border: 2px solid white;
  font-size: 1.1rem;
  margin: 0 0.5rem;
  width: 8.4rem;
  height: 3rem;
  font-family: $font-main;
  transition: .7s;

  &:hover {
    border-bottom: 2px solid $button-border;
  }

  &:active {
    transition: 0s;
    color: $prima-color;
    border-bottom: 2px solid $prima-color;
  }
}

a {
  text-decoration: none;
  color: black;
  transition: .7s;

  &:hover {
    color: $prima-color;
  }

  &:active {
    transition: 0s;
    color: $action-color;
  }
}

.res {
  color: $action-color;

  &:hover {
    border-bottom: 2px solid $action-color;
  }

  &:active {
    transition: 0s;
    color: $prima-color;
    border-bottom: 2px solid $prima-color;
  }
}

.button {
  font-size: 1.2rem;
  padding: 0.8rem 3rem;
  border-radius: 30px;
  box-shadow: 5px 5px 10px orange;
  transition: 1s;
  border: 0;
  background: $button-yurts;

  @include desktop {
    font-size: 1.6rem;
  }

  &:hover {
    color: $accent-color;
    background: $action-color;
    border-radius: 100px 0;
  }
}