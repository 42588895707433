#contact {

  h1 {
    margin-bottom: 2rem;
    text-align: center;
  }

  h5 {
    font-size: 1.3rem;

    @include desktop {
      font-size: 1.5rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .label {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 600px;
      width: 80%;

      @include desktop {
        width: 40%;
      }

      .input {
        padding: 0.7rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        opacity: 0.5;
        border: 0;
        border-radius: 0 20px;
        font-size: 1.1rem;
      }

      &:nth-of-type(5) {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
        }

        &:hover input ~ .checkmark {
          background-color: #ccc;
        }

        input:checked ~ .checkmark {
          background-color: $accent-color;
        }

        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        input:checked ~ .checkmark:after {
          display: block;
        }

        .checkmark:after {
          left: 9px;
          top: 1px;
          width: 8px;
          height: 19px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        h5 {
          margin-bottom: 2rem;
          font-size: 1.1rem;

          @include desktop {
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  .alert {
    margin-top: -2rem;
  }

  p {
    font-size: 1.1rem;
    color: $action-color;
    opacity: 0.9;
  }

  .alert_agree {
    margin-left: -2rem;
  }

  .thanks {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 90%;
      margin-bottom: 2rem;

      @include desktop {
        width: 50%;
      }
    }
  }

  .link {
    border-bottom: 1px solid black;
    transition: 1s;

    &:hover {
      border-bottom: 1px solid $accent-color;
    }
  }
}