#prices {

  h1 {
    text-align: center;
    padding-bottom: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
    text-align: center;
  }

  h4 {
    font-size: 1.3rem;

    @include desktop {
      font-size: 1.8rem;
    }
  }

  h5 {
    font-size: 1.1rem;

    @include desktop {
      font-size: 1.6rem;
    }
  }

  p {
    text-align: justify;
  }

  .price_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding-top: 2rem;

    .price {
      width: 50%;
      max-width: 800px;

      @include mobile {
        width: 90%;
      }
    }

    .seasons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        line-height: 2;
      }

      .calendar {
        font-size: 3rem;
        transition: 1s;

        &:hover {
          color: $action-color;
        }

        @include mobile {
          font-size: 2rem;
        }
      }

      .first {
        margin-left: -2rem;
      }

      .second {
        margin-left: -2.5rem;
      }
    }

    span {
      border-bottom: 1px solid black;
      margin-top: 1rem;
      margin-bottom: 3rem;
      width: 60%;
      max-width: 1000px;

      @include mobile {
        width: 95%;
      }
    }

    .bon {
      text-align: center;

      img {
        margin-top: 1rem;
      }
    }

    .information {
      margin-top: 3rem;
    }

    .additional {
      margin-top: 3rem;
    }

    .deposit {
      margin-top: 3rem;

      p:nth-of-type(2) {
        text-align: center;
        margin: 2rem 0;
        font-size: 1rem;

        @include mobile {
          font-size: 0.9rem;
        }
      }
    }
  }
}
